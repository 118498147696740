import React, {Component} from "react";
import {BrowserRouter as Router, Routes, Route, Link} from 'react-router-dom';
import Subtitles from "./Subtitles";
import ReadContent from "./ReadContent";

function TOC(props) {

    // shouldComponentUpdate(nextProps, nextState, nextContext) {
    //     // debugger;
    //     console.log("=====TOC ShouldComponentUpdate=====")
    //     // console.log(nextProps, nextState, nextContext);
    //     if (this.props.data === nextProps.data) {
    //         return false;
    //     } else
    //         return true;
    // }


    console.log("=====TOC Render=====");
    var tlist = [];
    var data = props.data;


    // debugger;
    // for (var i = 0; i < data.length; i++) {
    //     tlist.push(
    //         <li key={data[i].id}>
    //             <Link to={"/content/" + data[i].id}
    //                   data-id={data[i].id}
    //                   onClick={function (arg1, arg2, e) {
    //                       e.preventDefault();
    //                       // debugger;
    //                       if (arg2)
    //                           props.onIndexClicked(e.target.dataset.id);
    //                       else
    //                           props.onIndexClicked(arg1);
    //                   }.bind(this, data[i].id, false)}>{data[i].title}
    //             </Link>
    //         </li>)
    // }

    // debugger;
    var elms = data.map((data) => (
        <li>
            <Link
                style={{display: "block", marginTop: "5px", marginBottom: "0px"}}
                to={`/test/${data.title}`}
                key={data.title}
                onClick={function (arg1, arg2, e) {
                    e.preventDefault();
                    // debugger;
                    if (arg2)
                        props.onIndexClicked(e.target.dataset.id);
                    else
                        props.onIndexClicked(arg1);

                }.bind(this, data.id, false)}
            >{data.title}
            </Link>
        </li>
    ));

    return (
        <Router>
            <div>
                <ul>
                    {elms}
                </ul>
            </div>
            <Routes>
                <Route exact path="/content/html" element={<ReadContent title={"_title"} desc={"_desc"}/>}/>
            </Routes>
        </Router>
    );
}

export default TOC;