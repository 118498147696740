import logo from './logo.svg';
import './App.css';
import React, {useEffect, useState} from 'react';
import Subtitles from './Components/Subtitles'
import Control from './Components/Control'
import ReadContent from './Components/ReadContent'
import CreateContent from './Components/CreateContent'
import TOC from './Components/TOC'

import axios from 'axios'
import {BrowserRouter as Router, Routes, Route, Switch, Link} from 'react-router-dom';


class App extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            subject: {title: "Web", subs: "World Wide Web"},
            mode: "window",
            status: "",
            contentsId: 0,
            contents: [
                {id: 2, title: "HTML", desc: "I want to know HTML"},
                {id: 3, title: "CSS", desc: "I want to know CSS"},
                {id: 4, title: "Javascript", desc: "I want to know Javascript"},
            ],
            message: "",
        }
    }


    getArticle() {
        var _title, _desc, _article;

        if (this.state.mode === "window") {
            _title = this.state.subject.title;
            _desc = this.state.subject.subs;
            _article = <ReadContent title={_title} desc={_desc}/>;
        } else if (this.state.mode === "read") {
            // strict equality 검사
            var isEqual = this.state.contents[0].id === this.state.contentsId;

            // debugger;
            var selectedCont = this.state.contents.find(elm => elm.id == this.state.contentsId)
            if (selectedCont == undefined) {
                selectedCont = {title: "No Page", desc: "Cannot find lined content"}
            }

            _title = selectedCont.title;
            _desc = selectedCont.desc;
            _article = <ReadContent title={_title} desc={_desc}/>;
        } else if (this.state.mode === "create") {
            _article = <CreateContent onSubmit={function (_title, _desc) {

                var _contents = Array.from(this.state.contents);

                // 가장 높은 Id 값을 찾기
                var maxCont = _contents.reduce((maxCont, cont) => {
                    if (cont.id > maxCont.id)
                        return cont;
                    else
                        return maxCont;
                })

                _contents.push({id: maxCont.id + 1, title: _title, desc: _desc});
                this.setState({contents: _contents})
            }.bind(this)
            }/>;
        }
        return _article;
    }

    componentDidMount() {
        // axios.get('http://172.30.1.19:5000/flask/hello').then(response => {
        //     console.log("SUCCESS", response)
        //     this.setState({message: response.data.message})
        // }).catch(error => {
        //     console.log(error)
        // })
    }


    render() {

        return (
            <div>
                <Subtitles title={this.state.subject.title} sub={this.state.subject.subs} onChangePage={function (msg) {
                    // debugger;
                    this.setState({mode: "window"})
                    this.setState({status: msg})
                }.bind(this)}/>
                <TOC data={this.state.contents} onIndexClicked={function (id) {
                    // debugger;
                    this.setState({mode: "read"});
                    this.setState({contentsId: id})
                    this.setState({status: "clicked list"})


                }.bind(this)}/>
                <Control handleCommand={function (_mode) {
                    // debugger;
                    console.log(_mode);
                    this.setState({mode: _mode})
                }.bind(this)}/>
                {this.getArticle()}
                <div>
                    <h3>BackEnd Respond</h3>
                    {this.state.message}
                </div>
                <div>
                    {/*<Routes>*/}
                    {/*    <Route exact path="/" component={Subtitles}></Route>*/}
                    {/*    <Route path="/html" component={TOC}></Route>*/}
                    {/*</Routes>*/}

                </div>
            </div>
        );
    }
}

export default App;
