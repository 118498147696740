import React, {Component} from "react";

class Control extends React.Component{
    handleClick=(_mode, event) =>{
        // debugger;
        console.log(event);
        event.preventDefault();
        this.props.handleCommand(_mode);
    }
    render() {
        return(
            <ul>
                <li><a href="/create" onClick={this.handleClick.bind(this,"create")}>Create</a></li>
                <li><a href="/update" onClick={this.handleClick.bind(this,"update")}>Update</a></li>
                <button>Delete</button>
            </ul>
        );
    }
}

export default Control;