import React, {Component} from "react";
import ReadContent from "./ReadContent";

class Subtitles extends React.Component{
    render() {
        return (
            <article>
                <h1><a href="/" onClick={function(e){
                    console.log(e);
                    e.preventDefault();
                    this.props.onChangePage("clicked titles");
                }.bind(this)}>
                    {this.props.title}</a></h1>
                {this.props.sub}
            </article>
        );
    }
}

export default Subtitles;